const Page = props => {
	const { pageData, error } = props;
	return (
		<div className="errorBody main500">
			<div>
				<img
					className="responsiveImage main404"
					src="/static/img/MaintenanceImage.jpg"
				/>
			</div>
		</div>
	);
};

export default Page;
