import axios from "axios";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
import { setInStorage, getFromStorage, isInternetExplorer, get_browser } from "../../utils";

const tough = require('tough-cookie');
import axiosCookieJarSupport from 'axios-cookiejar-support';

let instance;
let isIE = false;

if (typeof window !== "undefined") {
	//checking if browser is IE
	isIE = isInternetExplorer(get_browser(window.navigator.userAgent));
}

if (process.env.NODE_ENV !== "production") {
	instance = axios.create({
		baseURL: publicRuntimeConfig.API_ROOT,
		timeout: 1000000,
		responseType: "json",
		jar: new tough.CookieJar(),
	});
} else {
	instance = axios.create({
		baseURL: publicRuntimeConfig.API_ROOT,
		timeout: 600000,
		// adapter: cache.adapter,
		responseType: "json",
		jar: new tough.CookieJar(),
	});
}

axiosCookieJarSupport(instance);
instance.defaults.jar = new tough.CookieJar();
instance.interceptors.request.use(
	(config) => {
		config.headers["Accept-Language"] = getFromStorage("language") || "en";
		// config.headers["Access-Control-Max-Age"] = 600;
		config.params = config.params || {};
		config.params["key"] = publicRuntimeConfig.cacheKey || "default";
		if (typeof window === "undefined") {
			config.headers["Origin"] = publicRuntimeConfig.domain;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

/**
 * TODO:
 * Add the correct code at a later stage to implement error handling from server
 */

instance.interceptors.response.use(
	async function (response) {
		response.headers['Cache-Control', 's-maxage=60, stale-while-revalidate'];

		if (response.headers["set-cookie"]) {
			response.config.jar.removeAllCookiesSync();
			response.headers.cookie = response.headers["set-cookie"];
			response.headers["set-cookie"].forEach(element => {
				let cookie = tough.parse(element);
				response.config.jar.setCookieSync(cookie, response.config.baseURL);
			});
		}

		return response;
	},

	function (error) {
		console.log(error);
		
		if (error.response) {
			return error.response.data;
		}
		return Promise.reject(error);
	}
);

instance.defaults.withCredentials = true;
instance.defaults.headers.common["Accept"] = "application/json;charset=utf-8";
instance.defaults.headers.post["Content-Type"] = "multipart/form-data; boundary=12345";
instance.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
instance.defaults.headers.post["HTTP_X_REQUESTED_WITH"] = "XMLHttpRequest";

export default instance;
