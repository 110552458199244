export function isEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
	// return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(email)
}

export function isValidIDNumber(idNum) {
	idNum = idNum.toString().replace(" ", "");
	let r = /^\d{10}[0-1]\d{2}$/;
	if (idNum === '0000000000000') return false;
	if (!r.test(idNum)) return false;
	let n = idNum;
	let p1 = parseInt(n[0], 10) + parseInt(n[2], 10) + parseInt(n[4], 10) + parseInt(n[6], 10) + parseInt(n[8], 10) + parseInt(n[10], 10);
	let p2 = (parseInt(n[1] + n[3] + n[5] + n[7] + n[9] + n[11], 10) * 2).toString();
	let p3 = 0;

	for (let i = 0; i < p2.length; i++) {
		p3 += parseInt(p2[i]);
	}
	let check = 10 - (p1 + p3).toString()[(p1 + p3).toString().length - 1];
	let check_char = check > 9 ? check.toString()[1] : check.toString();
	if (check_char !== idNum[12]) return false;
	return true;
}


export function isValidPassportNumber(passportNum) {
	return true;
}

/* newer validate function for react components
* helps reduce the code clutter and copy pasting in the components a lot
*/
export function validate(value, type, caller) {
	var isValid = _isValid(value, type);
	caller.valid = isValid ? caller.valid : false;
	return isValid;
}

function _isValid(value, type, minLength, maxLength) {
	if (value == null) return false;
	switch (type) {
		case 'string':
			return value.trim().length >= minLength && value.trim().length <= maxLength;
		case 'array':
			return value.length >= minLength && value.length <= maxLength;
		case 'object':
			for (var key in value) {
				if (value.hasOwnProperty(key))
					return true;
			}
			return false;
		case 'notNullOrEmpty':
			return value !== null && value !== "";
		case 'htmlText':
			return value !== "<p><br></p>";
		default:
			return value.trim().length >= minLength && value.trim().length <= maxLength;
	}
}

export function isValid(value, type, minLength = 1, maxLength = 10000) {
	return _isValid(value, type, minLength, maxLength);
}

export function isValidDate(date) {
	return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export function isValidMobile(value) {
	let regex = /(0[1-9][0-9]{8,8})/
	return value.length === 10 && regex.test(value)
}

export function isValidContactNumber(value) {
	let contactNumberRegex = /^0[0-9]{9,9}/
	return value.length === 10 && contactNumberRegex.test(value)
}

export function isValidNumber(value) {
	if (value) {
		return !isNaN(value * 1)
	} else {
		return false;
	}
}

export function isValidPassword(pwd1, pwd2) {
	if (pwd1.trim() !== "" && pwd1 === pwd2) {
		if (pwd1.length < 6) {
			return false;
		}
		let re = /[0-9]/;
		if (!re.test(pwd1)) {
			return false;
		}
		re = /[a-z]/;
		if (!re.test(pwd1)) {
			return false;
		}
		re = /[A-Z]/;
		if (!re.test(pwd1)) {
			return false;
		}
	} else {
		return false;
	}
	return true;
}

export function isEmpty(data) {
	if (typeof (data) === 'object') {
		if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
			return true;
		} else if (!data) {
			return true;
		}
		return false;
	} else if (typeof (data) === 'string') {
		if (!data.trim()) {
			return true;
		}
		return false;
	} else if (typeof (data) === 'undefined' || typeof (data) === undefined) {
		return true;
	} else if (typeof (data) === 'null' || typeof (data) === null) {
		return true;
	} else {
		return false;
	}
};

export function hasProperty(object, key) {
	if (object != undefined && object != null) {
		return object.hasOwnProperty(key) ? object.hasOwnProperty(key) : false;
	} else {
		return false;
	}
};

export function betweenMinMax(min, max, value) {
	return value >= min && value <= max;
}

export function validateMinLength(length, value) {
	return value.length >= length;
}

export function validateMaxLength(length, value) {
	return value.length > length;
}

export function countDecimals (value) {
	if(Math.floor(value.valueOf()) === value.valueOf()) return 0;
	let sep = value.toString().split(".");
	if (sep.length > 1){
		return sep[1].length;
	} else {
		return 0;
	}
}

export function validateMaxNumberLength(length, decimalLength, value) {
	let decimalCount = countDecimals(value);
	if (decimalLength != 0 && decimalCount > decimalLength) return false;

	let sep = value.toString().split(".");

	if (sep[0] && sep[0].length > length ) {
		return false;
	}

	return true;
}