import { isEmpty, hasProperty } from "./validate";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

export function transformCategoryForGTM(cat) {
	if (!isEmpty(cat)) {
		switch (cat) {
			case "car":
				return "cars";
			case "motorcycle":
				return "motorcycles";
			case "power":
				return "power-products";
			case "marine":
				return "marine";
			default:
				return cat;
		}
	}
	return cat;
}

export const getRegionForGoogleMaps = () => {
	let regionSplit = publicRuntimeConfig.localRegion && publicRuntimeConfig.localRegion.split(',').map((v) => {
		let val = v.trim();
		if (val.length > 0) {
			return val;
		}
	});

	if (!regionSplit || regionSplit.length === 0) {
		regionSplit = ["za"];
	}

	return regionSplit;
};
