import React, { createContext, Component } from 'react';

import { Router, withRouter } from 'next/router';
import { hasProperty, isEmpty } from '../../utils/validate';
import { addDataLayerPageview } from "../../utils/data.layer.utils/data.layer.util";

export const DataLayerContext = createContext();

class DataLayerContextProvider extends Component {
	handleRouteChange() {
		const pathname = this.props.router.asPath;

		let title = "Honda | Honda";
		let seo = {};

		if (hasProperty(this.props, 'pageData') && hasProperty(this.props.pageData, 'seo')) {
			seo = this.props.pageData.seo;
		}

		if (hasProperty(this.props, 'data') && hasProperty(this.props.data, 'seo')) {
			seo = this.props.data.seo;
		}

		if (hasProperty(this.props, 'dehydratedState')) {
			const dehydratedState = this.props.dehydratedState;
			const query = dehydratedState.queries[0];
			const data = query.state.data;

			if (hasProperty(data, 'seo')) {
				seo = data.seo;
			}
		}

		if (!isEmpty(seo) && hasProperty(seo, 'MetaTitleContainer')) {
			title = seo.MetaTitleContainer.title.title
		}

		addDataLayerPageview(pathname, title);
	}

	componentDidMount = () => {
		Router.events.on('routeChangeComplete', () => this.handleRouteChange());
	}

	render() {
		return (
			<DataLayerContext.Provider value={{ dataLayerName: 'PageDataLayer' }}>
				{this.props.children}
			</DataLayerContext.Provider>
		)
	}
}

export default withRouter(DataLayerContextProvider);