import { transformCategoryForGTM } from "../transformers.utils";
import { hasProperty } from "../validate";


export function transformDataLayerValuesForGTM(values) {
	let dataLayerValues = values;

	if (hasProperty(dataLayerValues, "category")) {
		dataLayerValues.category = transformCategoryForGTM(dataLayerValues.category);
	}

	return dataLayerValues;
}


export function pickDataForGTM(payload) {
	let data = {};

	if (hasProperty(payload, "category")) {
		data.category = transformCategoryForGTM(payload.category);
	}

	if (hasProperty(payload, "title")) {
		data.title = payload.title;
		if (hasProperty(payload, "seo") && !isEmpty(payload.seo) && hasProperty(payload.seo, 'MetaTitleContainer')) {
			data.title = payload.seo.MetaTitleContainer.title.title
		}
	}

	if (hasProperty(payload, "slug")) {
		data.slug = payload.slug;
	}

	if (hasProperty(payload, "formData") && !isEmpty(payload.formData)) {
		const formData = payload.formData;
		if (hasProperty(formData, "category")) {
			let form = {};

			if (hasProperty(formData, "name")) {
				form.name = formData.name
			}

			if (hasProperty(formData, "handle")) {
				form.handle = formData.handle
			}

		}
	}

	return data;
}