const Page = props => {
	const { pageData, error } = props;
	return (
		<div className="customContainer">
			<h3>Please note that you are using an outdated browser.</h3>
			<h4>
				Use any of the following browsers: <br />
				Edge <br />
				Chrome{" "}
				<a href="https://www.google.com/chrome/">
					https://www.google.com/chrome/
				</a>{" "}
				<br />
				FireFox{" "}
				<a href="https://www.mozilla.org/en-US/firefox/new/">
					https://www.mozilla.org/en-US/firefox/new/
				</a>
				<br />
			</h4>
		</div>
	);
};

export default Page;
