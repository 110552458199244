import TagManager from "react-gtm-module";
import { isEmpty } from "../validate";
import { transformDataLayerValuesForGTM } from "./general.data.layer.util";

export function addDataLayerPageview(pagePath, pageTitle) {
	
	let tagManagerArgs = {
		dataLayerName: 'PageDataLayer',
		dataLayer: {
			event: 'Pageview',
			values: {
				page: {
					path: pagePath,
					title: pageTitle
				}
			}
		}
	}

	if (typeof window !== 'undefined' && tagManagerArgs !== undefined) {
		if (process.env.NODE_ENV !== 'production') {
			console.log('add page view', tagManagerArgs.dataLayer.event, tagManagerArgs.dataLayer.values);
		}

		TagManager.dataLayer(tagManagerArgs);
	}
}

export function addDataLayerEvent(eventAction, eventValues = {}) {
	if (!isEmpty(eventAction) && !isEmpty(eventValues)) {
		let tagManagerArgs = {};

		tagManagerArgs.dataLayerName = "PageDataLayer";

		let dataLayer = {};
		const dataLayerVal = transformDataLayerValuesForGTM(eventValues);

		if (!isEmpty(dataLayerVal)) {
			dataLayer.event = eventAction;
			dataLayer.values = dataLayerVal;
			tagManagerArgs.dataLayer = dataLayer;
			if (process.env.NODE_ENV !== 'production') {
				console.log('add data layer', eventAction, dataLayerVal);

			}
			if (typeof window !== 'undefined') {
				TagManager.dataLayer(tagManagerArgs);
			}
		}
	}
}