import React, { createContext, Component } from "react";
import { withRouter } from "next/router";
import parse from "html-react-parser";
import TagManager from "react-gtm-module";
import cookieCutter from "cookie-cutter";
import { getQueryFn } from "../../utils/api/queryFunction";
import { SiteContext } from "./SiteContext";

import CookieSvg from "../../Assets/svg/Cookies.svg";

export const CookieContext = createContext();

class CookieContextProvider extends Component {
	state = {
		cookieData: null,
		cookiesExists: false,
		hidden: false,
	};

	async componentDidMount() {
		if (process.browser) {
			this.setState({ cookiesExists: this.doesCookieExist() });
			if (!this.state.cookiesExists) {
				const response = await getQueryFn('cookie');
				this.setState({ cookieData: response });
			}
		}
	}

	doesCookieExist = () => {
		let found = cookieCutter.get("htc");
		if (found) {
			return true;
		}
		return false;
	};

	setBrowserCookie = (selected) => {
		let name = "htc";
		let value = 0;
		let days = 365;
		let date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		let expires = "; expires=" + date.toUTCString();
		if (selected === "accept") {
			value = 1;
		}
		cookieCutter.set(name, value, { expires: expires, path: "/" });
		this.setState({ hidden: true });
	};

	createDataLayer = (button) => {
		let tagManagerArgs = {};
		let dataLayer = {};

		dataLayer = {
			event: "CookieAccept",
			title: button.title,
			selected: button.type,
			page: this.props.router.asPath,
		};

		tagManagerArgs = {
			dataLayerName: "PageDataLayer",
			dataLayer: dataLayer,
		};

		if (typeof window !== "undefined" && tagManagerArgs !== undefined) {
			TagManager.dataLayer(tagManagerArgs);
		}
	};

	buttons = (deviceType) => {
		return this.state.cookieData.buttons.map((button, i) => {
			return (
				<button
					onClick={() => {
						this.createDataLayer(button);
						this.setBrowserCookie(button.type);
					}}
					className={
						`waves-effect waves-light hButton btn-large buttonRed ${deviceType == "desktop" ? "" : 'alt'}`
					}
					key={i}
				>
					{button.title}
				</button>
			);
		});
	};

	render() {
		return (
			<CookieContext.Provider value={{cookieName: 'cookieMonster'}}>
				{this.state.cookieData &&
				!this.state.cookiesExists &&
				!this.state.hidden ? (
					<SiteContext.Consumer>{({ deviceType }) => {
						return (
							<div className="cookies">
								<div className="customContainer">
									<div className={`row ${deviceType == "desktop" && "wrapper"}`}>
										{deviceType == "desktop" && (
											<div className="col l1">
												<div className="lg img">
													<CookieSvg height="100" width="100" />
												</div>
											</div>
										)}

										<div className="col s12 m12 l7">
											<div className="title-desc">
												<div className="img-title">
													{deviceType != "desktop" && (
														<div className="img">
															<CookieSvg height="60" width="60" />
														</div>)
													}
													<h4 className={`${deviceType != "desktop" ? "wht-space":""}`}>{this.state.cookieData.title}</h4>
												</div>
												{parse(this.state.cookieData.description)}
											</div>
										</div>
										<div className="col s12 m12 l3">
											{this.buttons(deviceType)}
										</div>
									</div>
								</div>
							</div>
						);
					}}
					</SiteContext.Consumer>
				) : null}
				{this.props.children}
			</CookieContext.Provider>
		);
	}
}

export default withRouter(CookieContextProvider);

