import React, { createContext, useEffect, Component } from 'react'
export const SiteContext = createContext();

class SiteContextProvider extends Component {
    
    state = {
        activeSideMenu: false,
        deviceType: 'desktop',
        dom: {
            width: 0,
            height: 0,
        }
    };

    resizeHandler = () => {
        let tablet = window.matchMedia( "(min-width: 600px) and (max-width: 1024px)" );
        // let desktop = window.matchMedia( "(min-width: 1025px) and (max-width: 1920px)" );
        let desktop = window.matchMedia( "(min-width: 1025px)" );
        this.setState({
            deviceType: desktop.matches ? 'desktop' : tablet.matches ? 'tablet' : 'mobile',
            dom: {
                width: window.innerWidth,
                height: window.innerHeight,
            }
        });
    }

    toggleSideMenu = (toggle) => {
        this.setState({ activeSideMenu: toggle })
    }

    componentDidMount = () => {
        let tablet = window.matchMedia( "(min-width: 768px) and (max-width: 1024px)" );
        let desktop = window.matchMedia( "(min-width: 1025px)" );
        this.setState(
            { 
                deviceType: desktop.matches ? 'desktop' : tablet.matches ? 'tablet' : 'mobile',
                dom: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                }
            }
        );

        window.addEventListener('resize', this.resizeHandler);
        return(() => {
            window.removeEventListener('resize', this.resizeHandler);
        });
    }

    render(){
        return(
            <SiteContext.Provider value={{...this.state, toggleSideMenu: this.toggleSideMenu }}>
                {this.props.children}
            </SiteContext.Provider>
        )
    }
}

export default SiteContextProvider;